import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF as facebookIcon,
  faInstagram as instagramIcon,
} from '@fortawesome/free-brands-svg-icons';
import { graphql, PageProps } from 'gatsby';
import * as React from 'react';
import Handwerk from '../components/Handwerk';
import Logo from '../components/Logo';
import {
  GatsbyImage,
  getImage,
  IGatsbyImageData,
  ImageDataLike,
} from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import Obfuscate from 'react-obfuscate';
import LogoFooter from '../components/LogoFooter';
import SocialIcons from '../components/SocialIcons';
// import Logo from '../images/hbk.svg'

// markup
const IndexPage = ({ data }: PageProps<Queries.HomePageQuery>) => {
  const image = getImage(data.teaserImage as ImageDataLike) as IGatsbyImageData;
  const contactImage = getImage(
    data.contactImage as ImageDataLike,
  ) as IGatsbyImageData;
  const contactBgImage = convertToBgImage(contactImage);
  const teamImages = data.teamImages.nodes.map<IGatsbyImageData>(
    (node) => getImage(node as ImageDataLike) as IGatsbyImageData,
  );
  const galleries = data.galleryImages.nodes.reduce((galleries, img) => {
    // console.log(img.);
    const key = img.relativeDirectory.replace('gallery/', '');
    const image = getImage(img as ImageDataLike) as IGatsbyImageData;
    if (typeof galleries[key] === 'undefined') {
      galleries[key] = { title: key, teaser: key, images: [image] };
    } else {
      galleries[key].images.push(image);
    }
    return galleries;
  }, {} as Record<string, { title: string; teaser: string; images: IGatsbyImageData[] }>);
  // console.log(data, teamImages, galleries);
  return (
    <main>
      <title>Holzbau Kröplien</title>
      <div className={'flex flex-col bg-brand-cyan md:flex-row'}>
        <div className={'md:w-2/3'}>
          <div className={'flex h-16 items-center bg-white p-4'}>
            <Logo className={'h-8'} />
          </div>
          <GatsbyImage
            image={image}
            alt={'Teaser'}
            className={'object-cover'}
          />
        </div>
        <div className={'flex flex-col md:w-1/3'}>
          <div className={'flex grow items-center justify-center'}>
            <div className={'p-8 text-white md:w-2/3 md:p-4'}>
              <Handwerk
                className={'mb-6 w-full border-b-4 fill-current pb-6'}
              />
              <p className={'font-medium'}>
                In allen 4 Wänden und auch auf jedem Grundstück steckt
                Potential. Wir besitzen nicht nur das Know How, sondern es ist
                auch unsere Leiden&shy;schaft ihr Eigentum wert&shy;schöpfend
                aus-/ um-/ fertigzubauen, gänzlich zu erneuern oder sogar ganz
                neues Ideen als Projekte gemeinsam mit Ihnen zu realisieren.
              </p>
            </div>
          </div>
          <div
            className={
              'flex-nowrap space-x-2 self-end rounded-l-full bg-white py-2 pl-3 pr-4 text-brand-cyan'
            }
          >
            <SocialIcons />
          </div>
        </div>
      </div>

      <div className={'flex flex-col bg-brand-cyan text-white md:flex-row'}>
        <div className={'m-8 md:m-12 md:w-2/5'}>
          <h1
            className={
              'mb-6 inline-block border-b-4 pb-6 text-4xl uppercase md:text-7xl'
            }
          >
            Über uns
          </h1>
          <p className={'font-medium'}>
            Wir sind eine mittelständische, kleine Zimmer&shy;manns&shy;firma,
            die sich auf den Raum Nord&shy;west&shy;mecklen&shy;burg fokussiert
            hat. Ich, Stefan Kröplien, bin vor gar nicht allzu langer Zeit in
            die Fuß&shy;stapfen meines Vaters getreten und habe dieses
            Unternehmen von ihm übernommen. In den letzten Jahren haben wir
            nicht nur unsern Fuhrpark aufrüsten können, sondern auch das Team.
            Ich beschäftige 6 Mitarbeiter, deren Aufgabengebiete sehr breit
            gefächert sind. Wir bedienen vom klassischen Holzbau bis zu
            Komplett-Sanierungen von Bestandsgebäuden nahezu alles.
          </p>
          <p></p>
        </div>
        <div className={'mb-8 md:my-12 md:grow'}>
          <div
            className={
              'mb-8 flex justify-between gap-2 md:mb-12 md:mr-12 md:gap-8'
            }
          >
            {teamImages.map((image, i) => (
              <GatsbyImage
                key={i}
                image={image}
                alt={'Teaser'}
                className={'w-1/3'}
              />
            ))}
          </div>
          <div
            className={
              'relative flex flex-col space-y-4 px-8 before:absolute before:mx-3 before:h-full before:w-1 before:bg-white md:flex-row md:space-x-4 md:space-y-0 md:px-0 md:before:mx-0 md:before:my-3 md:before:h-1 md:before:w-full'
            }
          >
            {data.site?.siteMetadata?.timeline?.map((text, i) => (
              <div
                key={i}
                className={
                  'flex items-center space-x-2 md:flex-col md:space-x-0 md:space-y-4'
                }
              >
                <div
                  className={
                    'ml-0.5 h-6 w-6 shrink-0 rounded-full bg-white md:ml-0 md:mt-0.5'
                  }
                >
                  {' '}
                </div>
                <div className={'shrink text-sm md:text-center'}>{text}</div>
              </div>
            ))}
            <div className={'hidden w-12 md:block'}> </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-8 md:m-8 md:grid-cols-4">
        <div className={'m-8 md:col-span-2 md:m-0'}>
          <h1
            className={
              'mb-6 table-caption border-b-4 border-brand-yellow pb-6 text-4xl uppercase text-brand-yellow md:text-7xl'
            }
          >
            Unsere Leistungen
          </h1>
          <p className={'w-4/5 font-medium text-brand-brown'}>
            Hier finden Sie einen kleinen, groben Überblick unserer
            Dienstleistungen. Wir arbeiten sehr individuell und auf unseren
            Kunden abgestimmt. Es gibt nichts, was nicht möglich ist, also
            zögern Sie nicht, um uns zu kontaktieren. Egal für welches Anliegen,
            wir finden nicht irgendeine Lösung — Nein! Wir finden Ihre Lösung!
          </p>
        </div>
        {data.site?.siteMetadata?.galleries?.map((gallery) => (
          <a
            href={`#gallery-${gallery?.key}`}
            className={'group bg-brand-yellow p-8 text-center text-white'}
          >
            <GatsbyImage
              image={galleries[gallery?.key as string].images[0]}
              alt={'Teaser'}
              className={'aspect-square object-cover'}
            />
            <h2 className={'my-4 text-xl font-bold uppercase'}>
              {gallery?.title}
            </h2>
            <div className={'group-hover:underline'}>Mehr!</div>
          </a>
        ))}
      </div>

      <BackgroundImage
        Tag={'div'}
        {...contactBgImage}
        preserveStackingContext
        className={'my-8 flex flex-col'}
      >
        <div
          className={
            'flex flex-col items-center justify-end self-end bg-brand-cyan p-8 text-white md:w-2/3 md:flex-row md:rounded-l-full md:p-12'
          }
        >
          <div className={'md:order-2 md:w-1/2 md:pl-8'}>
            <h1
              className={
                'mb-6 inline-block border-b-4 pb-6 text-4xl uppercase md:text-7xl'
              }
            >
              Kontakt
            </h1>
            <div className={'text-lg'}>
              <div className={'font-bold'}>Holzbau Kröplin</div>
              <div>Inh. Stefan Kröplin</div>
              <div>Boienhagen 10</div>
              <div>23936 Upahl</div>
              <div className={'mt-4'}>
                <span className={'block md:inline'}>E-Mail:</span>{' '}
                <Obfuscate email="info@holzbau-kroeplien.de" />
              </div>
              <div className={'mb-4'}>
                <span className={'block md:inline'}>Mobil:</span>{' '}
                <Obfuscate tel="+491751861381">0175 18 613 81</Obfuscate>
              </div>
              <div className={'font-medium'}>
                Sie möchten eine bauliche Veränderung? Dann kontaktieren Sie uns
                gerne bevorzugt per E-Mail oder über dieses Kontaktfomular.
              </div>
            </div>
          </div>
          <div
            className={
              'flex flex-col space-y-2 md:order-1 md:w-1/2 md:pl-16 md:pr-8'
            }
          >
            <label className="block">
              <input
                type="text"
                className={
                  'mt-0 block w-full border-0 border-b-4 border-white bg-brand-cyan px-0.5 focus:border-brand-brown focus:ring-0'
                }
                placeholder={''}
              />
              <span className={'text-sm'}>Name, Vorname</span>
            </label>
            <label className="block">
              <input
                type="text"
                className={
                  'mt-0 block w-full border-0 border-b-4 border-white bg-brand-cyan px-0.5 focus:border-brand-brown focus:ring-0'
                }
                placeholder={''}
              />
              <span className={'text-sm'}>E-Mail</span>
            </label>
            <label className="block">
              <input
                type="text"
                className={
                  'mt-0 block w-full border-0 border-b-4 border-white bg-brand-cyan px-0.5 focus:border-brand-brown focus:ring-0'
                }
                placeholder={''}
              />
              <span className={'text-sm'}>Telefonnummer</span>
            </label>
            <label className="block">
              <textarea
                rows={2}
                className={
                  'mt-0 block w-full border-0 border-b-4 border-white bg-brand-cyan px-0.5 focus:border-brand-brown focus:ring-0'
                }
                placeholder={''}
              />
              <span className={'text-sm'}>Nachricht</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className={
                  'focus:brand-brown rounded-full border-0 text-brand-brown focus:ring-0'
                }
              />
              <span className="ml-2 text-xs">
                Mit dem Absenden stimme ich den
                Daten&shy;schutz&shy;bestimmungen zu.
              </span>
            </label>
            <input
              type="submit"
              className={
                'self-end rounded-2xl bg-white px-3 py-2 text-lg font-medium text-brand-cyan'
              }
              value="Absenden"
            />
          </div>
        </div>
      </BackgroundImage>

      <div className={'m-8 md:m-20'}>
        <h1
          className={
            'mb-6 inline-block border-b-4 border-brand-yellow pb-6 text-4xl uppercase text-brand-yellow md:text-7xl'
          }
        >
          Gallerie
        </h1>
        <p className={'font-medium text-brand-brown'}>
          Wir geben Ihnen gerne einen Einblick in unseren Alltag rund ums
          Handwerk und Haus. Auch Sie könnten bald einen Teil dieser Gallerie
          füllen.
        </p>
      </div>
      {data.site?.siteMetadata?.galleries?.map((gallery, i) => (
        <div
          key={gallery?.key}
          id={`gallery-${gallery?.key}`}
          className={'mb-4 flex flex-col md:mb-8 md:flex-row md:space-x-24'}
        >
          <div
            className={
              'sticky top-0 z-10 flex items-center bg-brand-yellow py-4 pl-8 text-2xl font-medium uppercase text-white md:h-24 md:w-1/3 md:rounded-r-full md:pl-20 md:text-3xl'
            }
          >
            {gallery?.title}
          </div>
          <div className={'mt-4 grow md:mt-0 md:pr-24'}>
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-8">
              {galleries[gallery?.key as string].images.map((image, i) => (
                <GatsbyImage
                  key={i}
                  image={image}
                  alt={'Teaser'}
                  className={'object-cover'}
                />
              ))}
            </div>
          </div>
        </div>
      ))}

      <footer
        className={
          'm-8 flex flex-col space-y-6 text-brand-brown md:flex-row md:space-y-0 md:space-x-8'
        }
      >
        <div className={'mt-8 flex justify-center'}>
          <LogoFooter className={'w-full max-w-xs'} />
        </div>
        <div
          className={
            'flex grow flex-col items-center justify-center space-x-2 text-center md:flex-row md:self-end'
          }
        >
          <div>Na? Neugierig geworden?</div>
          <div className={'space-x-2'}>
            <SocialIcons />
          </div>
        </div>
        <div className={'flex flex-col text-center md:self-end md:text-left'}>
          <div>Impressum</div>
          <div>Datenschutz</div>
          <div>AGB</div>
        </div>
      </footer>

      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
      {/*<div className={'h-96'}></div>*/}
    </main>
  );
};

export default IndexPage;

export const query = graphql`
  query HomePage {
    site {
      siteMetadata {
        title
        timeline
        galleries {
          key
          title
          teaser
        }
      }
    }
    teaserImage: file(relativePath: { eq: "teaser.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    contactImage: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    teamImages: allFile(
      filter: { relativeDirectory: { eq: "team" }, extension: { eq: "jpg" } }
      sort: { fields: [relativeDirectory, modifiedTime], order: ASC }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
    galleryImages: allFile(
      filter: {
        relativeDirectory: { glob: "gallery/*" }
        extension: { eq: "jpg" }
      }
      sort: { fields: [relativeDirectory, modifiedTime], order: ASC }
    ) {
      nodes {
        relativeDirectory
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`;
