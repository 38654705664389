import * as React from 'react';
import {
  faFacebookF as facebookIcon,
  faInstagram as instagramIcon,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SocialIcons = (props: React.HTMLAttributes<HTMLElement>) => (
  <>
    <FontAwesomeIcon icon={instagramIcon} />
    <FontAwesomeIcon icon={facebookIcon} />
    <span>holzbau_kroeplien</span>
  </>
);

export default SocialIcons;
